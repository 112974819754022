<template lang="pug">
div
  van-form(@submit='onSubmit')
    van-cell-group(v-for="(group, gIndex) of groups" :key="gIndex")
      component(v-for="(vf, vIndex) of group.fields" :key="vIndex" :is="vf.component ? vf.component : 'van-field'" 
        :label="vf.label"
        v-model="data[group.key][vf.key]"
        v-bind="vf.attrs"
      )
    div(style='margin: 16px;')
      van-button(round block type='info' native-type='submit' :disabled='commitState')
        | 提交

</template>

<script>
export default {
  data() {
    return {
      commitState: false,
      groups: [
        {
          key: 'data',
          fields: [
            {
              label: '头像',
              key: 'ui_head',
              component: 'UploaderField',
              attrs: {
                multiple: false,
                maxCount: 1
              }
            },
            {
              label: '昵称',
              key: 'ui_name'
            },
            {
              label: '手机号',
              key: 'u_mobile',
              attrs: {
                readonly: true
              }
            }
          ]
        }
      ],
      data: {
        data: {
          ui_head: [],
          ui_name: '',
          u_mobile: ''
        }
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
    userInfo(info) {
      
      this.$set(this.data.data, 'ui_name', info.ui_name)
      this.$set(this.data.data, 'u_mobile', info.u_mobile)
      this.$set(this.data.data, 'ui_head', [{url: info.ui_head}])
    }
  },
  methods: {
    async onSubmit() {
      this.commitState = true
      let params = {}
      if (this.data.data.ui_head.length > 0) {
        params.ui_head = this.data.data.ui_head[0].url
      }
      params.ui_name = this.data.data.ui_name
      try {
        await this.$api.SetUserInfo(params)
        let res = (await this.$api.GetUserInfo(this.$store.state.companyInfo.id)).data
        res = {
          ...res,
          ...res.info_poi_user_info,
          seller_poi_sell_users: res.sell_users.id,
          ...res.sell_users
        }

        this.$store.commit('setLoginInfo', {
          isLogined: true,
          userInfo: res
        })
        this.$toast('修改资料成功')
        this.$router.push({name: 'home'})

      } catch (e) {
        this.$toast(this.$error(e))
      }
      this.commitState = false
    }
  },
  async activated() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      rightText: '',
      rightArrow: false,
    })

    this.$set(this.data.data, 'ui_name', this.userInfo.ui_name)
    this.$set(this.data.data, 'u_mobile', this.userInfo.u_mobile)
    let res = this.userInfo.ui_head
    if (res && res.indexOf('b_art_img') == -1) {
        if (res.startsWith('files')) {
          const newRes = 'http://cdn.dpjia.com/' + res
          res = newRes
        }
    } else {
      res = ''
    }
    this.$set(this.data.data, 'ui_head', [{url: res}])
  }
}
</script>